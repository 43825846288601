<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            type="edit"
            codeGroupCd="EDU_KIND_FST_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdLarge"
            label="교육종류1"
            v-model="searchParam.educationKindCdLarge"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            type="edit"
            codeGroupCd="EDU_KIND_SEC_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdSmall"
            label="교육종류2"
            v-model="searchParam.educationKindCdSmall"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 교육구분 -->
          <c-select
            type="search"
            codeGroupCd="EDU_CLASS_CD"
            itemText="codeName"
            itemValue="code"
            name="educationTypeCd"
            label="교육구분"
            v-model="searchParam.educationTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 법정교육 여부 -->
          <c-select
            :editable="editable"
            type="search"
            codeGroupCd="MDM_CHECK_KIND_CD"
            itemText="codeName"
            itemValue="code"
            label="법정교육 여부"
            name="legalEduYn"
            v-model="searchParam.legalEduYn">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="교육과정 목록"
      tableId="accidentSeq"
      rowKey="eduCourseId"
      :columns="grid.columns"
      :selection="popupParam.type"
      :data="grid.data"
      :merge="grid.merge"
      height="400px"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group>
            <c-btn label="선택" icon="check" @btnClicked="select" />
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "education-curriculum-pop",
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        merge: [{ index: 0, colName: "plantNm" }],
        columns: [
          {
            name: "plantNm",
            field: "plantNm",
            label: "사업장",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationCourseName",
            field: "educationCourseName",
            label: "교육과정",
            align: "left",
            style: 'width:200px',
            sortable: true,
            type: "link",
          },
          {
            name: "educationKindCdLargeName",
            field: "educationKindCdLargeName",
            label: "교육종류1",
            style: 'width:150px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationKindCdSmallName",
            field: "educationKindCdSmallName",
            label: "교육종류2",
            style: 'width:150px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationTypeName",
            field: "educationTypeName",
            label: "교육구분",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "legalEducationFlagName",
            field: "legalEducationFlagName",
            label: "법정교육여부",
            style: 'width:90px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationTimeName",
            field: "educationTimeName",
            label: "교육시간",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "estimatedEducationExpensesName",
            field: "estimatedEducationExpensesName",
            label: "예상교육비",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationPurpose",
            field: "educationPurpose",
            label: "학습목적",
            style: 'width:200px',
            align: "left",
            sortable: true,
          },
          {
            name: "relatedLawsName",
            field: "relatedLawsName",
            label: "관련법규",
            style: 'width:350px',
            align: "left",
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        educationKindCdSmall: null,
        educationKindCdLarge: null,
        educationTypeCd: null,
        useFlag: 'Y',
        legalEduYn: null,
      },
      editable: true,
      listUrl: "",
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.sop.edu.course.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = "교육 과정 상세"; // 교육 과정 상세
      this.popupOptions.param = {
        eduCourseId: row.eduCourseId ? row.eduCourseId : '',
      };
      this.popupOptions.target = () =>
      import(`${"./cc/educationCurriculumDetail.vue"}`);
      this.popupOptions.isFull = true;
      this.popupOptions.width = '100%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    select() {
      this.selectData = this.$refs['table'].selected;
      if (!this.selectData || this.selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '교육과정을 지정하세요.', // 교육과정을 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', {
          selectData: this.selectData,
        });
      }
    },
  },
};
</script>
